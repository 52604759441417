import { ASwiper } from '../abstract/ASwiper.js';

export class SwiperActus extends ASwiper {
    constructor() {
        super({
            slidesPerView: 1.4,
            spaceBetween: 26,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            breakpoints: {
                510: {
                    slidesPerView: 1.6
                },
                767: {
                    slidesPerView: 2.4
                },
                959: {
                    slidesPerView: 2.6
                },
                1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 33,
                    slidesOffsetBefore: 24,
                    slidesOffsetAfter: 24
                },
                1232: {
                    slidesPerView: 3,
                    spaceBetween: 62,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                }
            }
        });
    }
}