import Swiper from 'swiper';
import 'swiper/css';

export class ASwiper extends HTMLElement {

    constructor(swiperConf) {
        super();
        this.elClass = '.swiper-js-container';
        this.swiperConf = swiperConf;
    }

    init() {
        const swiperContainerEl = this.querySelector(this.elClass);

        if (swiperContainerEl && this.swiperConf) {
            // Change nextEl/prevEl to HTMLElement inside swiper instance
            if (this.swiperConf && this.swiperConf.navigation) {
                this.swiperConf.navigation.nextEl = this.querySelector(this.swiperConf.navigation.nextEl);
                this.swiperConf.navigation.prevEl = this.querySelector(this.swiperConf.navigation.prevEl);
            }
            this.swiper = new Swiper(swiperContainerEl, { ...this.swiperConf })

            return;
        }

        console.warn(this, ' ::: ', 'Swiper configuration no found or swiper container .swiper-js-container not found');
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        }, 250)
    }
}