import { ASwiper } from '../abstract/ASwiper.js';
import { Navigation } from 'swiper/modules'

export class SwiperEtudesDeCas extends ASwiper {
    constructor() {
        super({
            slidesPerView: 'auto',
            spaceBetween: 45,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                767: {
                    slidesPerView: 1.8,
                    slidesOffsetBefore: 20,
                    slidesOffsetAfter: 20
                },
                959: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 33,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0
                },
                1232: {
                    slidesPerView: 2,
                    spaceBetween: 33,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                }
            }
        });
    }
}