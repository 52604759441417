import './import.js';
import './classes/utils/polyfill.js';
import './style.js';
import './header.js';
import {Render} from "./classes/utils/Render";
import {Resizable} from "./classes/utils/Resizable";
import {AComponent} from "./classes/abstract/AComponent";
import {ucfirst} from "./classes/utils/ucfirst";
import { SwiperLogos } from './classes/components/SwiperLogos.js'
import { SwiperTemoignages } from './classes/components/SwiperTemoignages.js'
import { SwiperEtudesDeCas } from './classes/components/SwiperEtudesDeCas.js'
import { SwiperActus } from './classes/components/SwiperActus.js'
// import {Cookies} from "./classes/utils/Cookies";


if(!window.console || !window.console.log) {
    window.console = {log : function(){}, warn : function() {}}
}

/**
 * COUCOU C NOUS
 */
console.big = function() {
    console.log('%c   '+Array.from(arguments).join(',')+'   ', 'background: #000000; color: #ffffff');
};

console.log('%c FCINQ ', 'background: #000; color: #fff; font:bold 40px/40px impact, sans-serif;');


/**
 * écouteur scroll/resize/load
 * + RAF
 */
F._.wW = window.innerWidth;
F._.wH = window.innerHeight;
F._.scrollTop = window.pageYOffset;

F.Render = new Render();
F.Resizable = new Resizable();

import AOS from 'aos';

AOS.init({disable: 'mobile'});

let resizableElmts = [],
    scrollableElmts = [],
    lastScrollTop = 0,
    scrollTop = 0;
window.addEventListener('scroll', () => {
    scrollTop = window.pageYOffset;
    F._.scrollTop = scrollTop;
});

window.addEventListener('resize', () => {
    F._.wW = window.innerWidth;
    F._.wH = window.innerHeight;
    for (let i = 0, e = null; e = resizableElmts[i]; i++) {
        e.resize && e.resize(F._.wW, F._.wH);
    }
    F.Resizable.resize();
});

window.addEventListener('load', () => {
    F.Resizable.resize();
    AOS.refresh();
});


console.log("MAJ OK");
setTimeout(() => {
    AOS.refresh();
},5000);


setTimeout(() => {
    AOS.refresh();
},10000);

setTimeout(() => {
    AOS.refresh();
},15000);

setTimeout(() => {
    AOS.refresh();
},20000);

setTimeout(() => {
    AOS.refresh();
},25000);

setTimeout(() => {
    AOS.refresh();
},30000);

requestAnimationFrame(function tick() {
    //F.Render.update();
    if (lastScrollTop !== scrollTop) {
        F._.scrollTop = scrollTop;
        for (let i = 0, e = null; e = scrollableElmts[i]; i++) {
            e.scroll && e.scroll(scrollTop, lastScrollTop);
        }
        lastScrollTop = scrollTop;
    }

    requestAnimationFrame(tick);
});

/**
 * instancie un acomponent sur le body pour créer tout les components présents dans le DOM
 */
let bodyView = new AComponent({el : document.body});
console.log(bodyView);
bodyView.render();
bodyView.bind();
bodyView.willAppear();
bodyView.didAppear();
scrollableElmts.push(bodyView);

for(let key in bodyView.$refs) {
    if(bodyView.$refs.hasOwnProperty(key)) {
        F[ucfirst(key)] = bodyView.$refs[key];
    }
}


/*
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

//ga('create', F.CONFIG.GA_UA, 'auto');
//ga('set', 'anonymizeIp', true);

// TODO A REACTIVER EN PROD
/*
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5HP84R7');
window['ga-disable-'+F.CONFIG.GA_UA] = true;*/

/* let cookieContent = "<div data-component='PopinCookie'><p>"+F.i18n.cookie_text+"</p>" +
    "<div class='popin-cookie-cta-wrapper'><a class='inline-btn' href='"+F.i18n.cookie_more_link+"' target='_blank'><span>"+F.i18n.cookie_more+"</span></a> <a class='btn accept'><span>"+F.i18n.cookie_accept+"</span></a></div>" +
    "</div>";

if(!Cookies.get('accept-cookies') && F.CONFIG.DISABLE_COOKIES === false && !F.CONFIG.IS_BOT) {
    F.Popin.show(F.i18n.cookie_title, cookieContent, false);
} else if(Cookies.get('accept-cookie') === 'yes') {
    window['ga-disable-'+F.CONFIG.GA_UA] = false;
}*/

// Init web components
customElements.define('swiper-logos', SwiperLogos);
customElements.define('swiper-temoignages', SwiperTemoignages);
customElements.define('swiper-etudes-de-cas', SwiperEtudesDeCas);
customElements.define('swiper-actus', SwiperActus);

// Detect si on ajoute un bg vert au top de la page
function setHeaderTopBg() {
    const homeHeaderEl = document.querySelector('#page');
    const headerComputedStyle = window.getComputedStyle(homeHeaderEl);
    const pt = headerComputedStyle.getPropertyValue('padding-top');
    const mt = headerComputedStyle.getPropertyValue('margin-top');
    const space = `calc(${pt} + ${mt})`;
    const spacerEl = document.querySelector('.page-top-banner');

    document.querySelector(':root').style.setProperty('--page-top-space', space);
    spacerEl && spacerEl.classList.add('show');
}

function putTopBg() {
    const hasTopBg = document.querySelector('body').classList.contains('add-bg');
    if (hasTopBg) {
        window.addEventListener('resize', () => { setHeaderTopBg(); });
        setHeaderTopBg();
    }
}

putTopBg();






